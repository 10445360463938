import React from "react";
import SingleDropdown from "./SingleDropdown";

const StorstadningQ = () => {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Det här ingår i storstädningen
          </h2>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500 ta-center ">
            Se vad vårt grundpaket innehåller. Det går självklart att lägga till
            de tjänster du önskar. Med utbildade städare och miljövänliga
            städmaterial ser vi till att erbjuda bästa möjliga städning.
          </p>
          <SingleDropdown
            text="Allmänt / Alla utrymmen"
            items={[
              "Dammtorkar väggar.",
              "Dammsuger och våttorkar snickerier, lister, element, dörrar, dörrkarmar, fönsterbrädor, fria ytor, garderobsdörrar och skåpdörrar.",
              "Dammtorkar prydnadssaker, hyllor och tavlor.",
              "Dammsuger stoppade möbler.",
              "Avlägsnar fläckar från strömbrytare.",
              "Dammtorkar eluttag.",
              "Dammtorkar lampor.",
              "Putsar speglar.",
              "Dammsuger och moppar golv.",
            ]}
          />
          <SingleDropdown
            text="Kök (inkl. allmänna moment)"
            items={[
              "Rengör kyl och frys utvändigt.",
              "Rengör spis och ugn ut- och invändigt.",
              "Rengör ugnsgaller och ugnsplåt (1st ingår).",
              "Rengör fläkt och fläktfilter.",
              "Rengör utvändigt samt ovanpå skåp, hyllor och lådor (där man kommer åt).",
              "Rengör kakel/stänkskydd ovanför diskbänk.",
              "Rengör mikrovågsugn ut- och invändigt.",
              "Rengör ut- och invändigt i skåp där sopbehållare finns.",
              "Tömmer sopor och torkar sopkorg.",
              "Torkar av det som står framme t.ex. kaffemaskin, oljor, salt och peppar.",
              "Putsar diskho, blandare, propp och sil.",
              "Rengör köksbänk.",
            ]}
          />
          <SingleDropdown
            text="Toalett / Badrum (inkl. allmänna moment)"
            items={[
              "Rengör väggar och golv.",
              "Avfettar och avkalkar väggar där det är möjligt.",
              "Rengör hela duschen alt. badkaret.",
              "Rengör synlig och lättåtkomlig golvbrunn.",
              "Putsar blandare och duschmunstycke.",
              "Rengör synliga rör.",
              "Torkar badrumsskåp utvändigt.",
              "Rengör utvändigt vitvaror.",
              "Rengör kran och handfat.",
              "Rengör ut- och invändigt toalettstolen.",
            ]}
          />
          <SingleDropdown
            text="Tjänster som kan bokas separat"
            items={[
              "Fönsterputs",
              "Invändig rengöring av tömt kylskåp",
              "Invändig rengöring av tömda köksskåp och lådor",
              "Balkong- och garagestädning",
            ]}
          />
          <SingleDropdown
            text="Att tänka på inför storstädningen"
            items={[
              "Informera oss gärna om du har känsliga material, t.ex. marmor eller mässing i din bostad.",
              "Ju fler lösa föremål som är undanplockade desto mer fokus kan vi lägga på städningen.",
              "Montera av eventuell badkarsfront för att vi ska komma åt under och rengöra.",
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default StorstadningQ;
