import React, { useState } from "react";
import { Seo } from "../../components/seo";
import Layout from "../../hoc/Layout/Layout";
import Booking from "../../components/Booking/Booking";
import Instructions from "../../components/Instructions/Instructions";
import Faq from "../../components/FAQ/FAQStorstadning";
import StorstadningQ from "../../components/FAQ/StorstadningQ";
import { SERVICE_TYPES } from "../../enums/serviceTypes";

// markup
const IndexPage = () => {
  const [bookingActive, setBookingActive] = useState(false);
  const [serviceChosen, setServiceChosen] = useState(false);
  const [ableToGoBack, setAbleToGoBack] = useState(false);
  const [finishBookingStep, setFinishBookingStep] = useState();

  return (
    <Layout
      bookingActive={bookingActive}
      serviceChosen={serviceChosen}
      ableToGoBack={ableToGoBack}
      finishBookingStep={finishBookingStep}
      setFinishBookingStep={setFinishBookingStep}
    >
      <Seo title="Storstädning i Göteborg | Bilda Group" />
      <Booking
        bgType3
        setBookingActive={setBookingActive}
        choosenService={SERVICE_TYPES.Storstädning}
        firstStep={2}
        setServiceChosen={setServiceChosen}
        setAbleToGoBack={setAbleToGoBack}
        finishBookingStep={finishBookingStep}
        setFinishBookingStep={setFinishBookingStep}
      />
      {!bookingActive && (
        <>
          <StorstadningQ />
          <Instructions
            // bg
            text1="Ange ditt postnummer och hur stor din bostad är."
            text2="Se pris och välj det datum du önskar få storstädningen utförd. Vi återkopplar om datumet finns ledigt."
            text3="Vi utför storstädningen så att du kan lägga din tid och energi på annat. Vårt professionella städteam gör ordentligt rent in i minsta detalj."
          />
          <Faq />
          {/* <GradeSection /> */}
        </>
      )}
    </Layout>
  );
};

export default IndexPage;
